@import '~antd/dist/reset.css';
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700|Poppins:400,500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.toggle-activated {
  background-color: #14db14;
  vertical-align: top;
  color: #fff;
}

.toggle-deactivated {
  background-color: #ff0000;
  vertical-align: top;
  color: #fff;
  background-image: none;
}

.disabled-Edit-Icon {
  color: #8080807d;
}

.disabled-icon {
  color: rgba(0, 0, 0, 0.25) !important;
  /* Set the desired disabled color */
  pointer-events: none;
  /* Disable pointer events on the icon */
}

.Editable-Icon {
  color: #1890ff;
}

.ant-descriptions-item-label > span {
  font-weight: bold;
  font-size: small;
}

.contracts .ant-table-thead > tr > th {
  background: #2195f3 !important;
  color: #fff;
  padding: 8px 8px;
}

.contracts-PO-List .ant-table-cell {
    padding: 12px !important;
}

/* .contracts-list .ant-table-cell {
    padding: 12px !important;
} */

/* .contracts-PO-List .ant-table {
  border-spacing: 16px;
}*/

.contracts-PO-List .ant-table-thead > tr > th {
  background: #f2f7fd !important;
  color: #130202;
  /* padding: 8px 12px !important; */
}

.contracts-list .ant-table-thead > tr > th {
  background: #f2f7fd !important;
  color: #130202;
  /* padding: 8px 12px !important; */
}

.purchase-Details .ant-table-thead > tr > th {
  background: #f2f7fd !important;
  color: #130202;
}

.purchase-Details .ant-table-footer {
  padding: 0px !important;
  margin-top: 8px !important;
}

.approve {
  background-Color: #B2F9CC !important;
   border-Radius: 4px;
   color: #0B903C !important;
   padding: 12px 8px ;
   border: none;
}

.reject {
  background-Color: #FFD2D2 !important;
  border-Radius: 4px;
  color: #DE2C2C !important;
  padding: 12px 8px ;
  border: none;
}

.descriptions {
  font-weight: 500;
  color: #323F49;
}

/* .contracts-PO-List .ant-table-tbody>tr>td  {
    border: none !important;
}  */
/* 
.contracts-PO-List .ant-table-row {
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px #0000001a;
} */

.inner-table .ant-table-thead > tr > th {
  background: #fafafa !important;
  color: rgba(0, 0, 0, 0.88) !important;
  padding: 8px 8px;
}

/* .ant-table-thead > tr > th,
.ant-table tfoot > tr > th {
  padding: 8px 8px;
} */

/* .ant-table-thead>tr>th {
    background-color: #e6e0e0!important;
} */
/* 
.ant-table-thead>tr>th {
    background-color: #e6e0e0!important;
}
.ant-table.ant-table-bordered td, .ant-table.ant-table-bordered th {
    border: 0.5px solid #dee2e6;
} */

/* .ant-table-tbody > tr > td,
.ant-table tfoot > tr > td {
  padding: 0.3rem !important;
} */

/* .ant-card-head {
    min-height: 40px !important;
    line-height: 0;
} */

/* th {
    white-space: nowrap;
} */

.contracts
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th {
  text-align: center;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: bold;
}

.contracts .ant-table-thead > tr > th,
.contracts .ant-table-tbody > tr > td,
.contracts .ant-table tfoot > tr > th,
.contracts .ant-table tfoot > tr > td {
  padding: 0.3rem !important;
}

.contracts .ant-table-thead .ant-table-row-expand-icon-cell {
  background: #2195f3 !important;
  color: #fff;
}

.contracts .ant-table-thead .ant-table-selection-column {
  background: #2195f3 !important;
  color: #fff;
}

.ant-list-item {
  padding: 0.3rem !important;
}

.export-excel-btn {
  color: #fff;
  margin-right: 20px;
  background-color: #088f08;
  border-color: #088f08;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  -webkit-box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  border-radius: 4px;
}

.export-excel-btn:hover,
.export-excel-btn:focus,
.export-excel-btn:active,
.export-excel-btn.active {
  color: #fff;
  background-color: rgba(0, 128, 0, 0.9);
  border-color: rgba(0, 128, 0, 0.9);
}

.export-excel-btn-disabled {
  color: #fff;
  margin-right: 20px;
}

span.ant-descriptions-item-label {
  font-weight: bold;
  /* font-size: small; */
}

/* 
.ant-form-item {
    margin-bottom: 40px;
} */

/* material floating label and animated underline bar -- https://auralinna.blog/post/2018/how-to-create-material-design-like-form-text-fields*/

/* .form-field {
    display: block;
    margin-bottom: 16px;
}

.form-field__label {
    font-size: 0.75rem;
    transform: translateY(-14px);
    display: block;
    font-weight: normal;
    left: 0;
    margin: 0;
    padding: 18px 12px 0;
    position: absolute;
    top: 0;
    transition: all 0.4s;
    width: 100%;
}

.form-field__control {
    background: #eee;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.form-field__bar {
    border-bottom: 2px solid #b11adc;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    transform: scaleX(0);
    transition: all 0.4s;
    width: 1%;
}

.form-field__input,
.form-field__textarea {
    appearance: none;
    background: transparent;
    border: 0;
    border-bottom: 1px solid #999;
    color: #333;
    display: block;
    font-size: 1.2rem;
    margin-top: 24px;
    outline: 0;
    padding: 0 12px 10px 12px;
    width: 100%;
}

.form-field__input:-ms-input-placeholder~.form-field__label,
.form-field__textarea:-ms-input-placeholder~.form-field__label {
    font-size: 1.2rem;
    transform: translateY(0);
}

.form-field__input:placeholder-shown~.form-field__label,
.form-field__textarea:placeholder-shown~.form-field__label {
    font-size: 1.2rem;
    transform: translateY(0);
}

.form-field__input:focus~.form-field__label,
.form-field__textarea:focus~.form-field__label {
    color: #b11adc;
    font-size: 0.75rem;
    transform: translateY(-14px);
}

.form-field__input:focus~.form-field__bar,
.form-field__textarea:focus~.form-field__bar {
    border-bottom: 2px solid #b11adc;
    transform: scaleX(150);
}

.form-field__textarea {
    height: 150px;
} */
/* https://codesandbox.io/s/antd-float-label-forked-k8p00?file=/FloatInput/index.js ---https://github.com/ant-design/ant-design/issues/16323 */

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.default-card-class .ant-card-head {
  background-color: #2193f3 !important;
  color: #fff !important;
}

.highlighted-row {
  background-color: yellow;
  /* or any other styling you want */
}

/* .ant-card-small > .ant-card-body {
  padding: 6px !important; 
} */


.row-even {
  background-color: #fff; 
}

.row-odd {
  background-color: #F8FBFF;
}

.input-search {
  width: 300px;
  border-radius: 4px;
  border: 1px;
}

.filter-by-status {
  width: 175px;
  height: 28px;
  border-radius: 4px;
  border: 1px;
}

.so-card-title {
  color: #323F49;
  font-size: 24px;
  font-weight: 600;
}

.card-title {
  color: #323F49;
  font-size: 24px;
  font-weight: 600;
}

.so-card-container {
  background-color: #F5F5F5;
  border: none;
  overflow: hidden;
}

.card-container {
  background-color: #F5F5F5;
  border: none;
  overflow: hidden;
}

.so-card-shadow {
  border: none;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.05);
}

.card-shadow {
  border: none;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.05);
}

.action-icons {
  background-color: #0083C91A;
  color: #0295D4;
  padding: 6px;
  font-size: 16px;
  border-radius: 50%;
}

.mr-8 {
  margin-right: 8px;
}

.link {
  color: #0083C9 !important;
  font-weight: 500;
}

.so-row-spaced {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.new-button {
  padding: 12px;
  background-color: #0295D4 !important;
  color: #F5F5F5 !important;
}

.so-input-search-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.input-search-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.card-radius {
  border-radius: 8px;
}

.card-title-buttons {
  gap: 8px;
  margin-top: 16px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-16 {
  margin-top: 16px;
}

.table-other-columns {
color: #40474F;
font-weight: 400;
}

.ant-descriptions .ant-descriptions-row >th{
  padding-bottom: 0 !important;
}

.ant-descriptions .ant-descriptions-row >td{
  padding-bottom: 0 !important;
}
.ant-descriptions .ant-descriptions-title{
  color: '#323F49' !important
}
.ant-descriptions .ant-descriptions-header {
  margin-bottom: 8px !important
}

.ant-card .ant-card-head {
  border-bottom: 0 !important;
}

.drawer-heading {
  font-size: 20px;
  color: #323F49;
  font-weight: 600;
}

.drawer-close-icon {
  font-size: 20px;
  color: #6B7280;
  font-weight: 600;
  box-shadow: 'none'
}

.standard-row {
  padding: 5px 10px;
  background-color: #F7F9FC;
  margin: 5px 0px;
  border-radius: 5px;
  width: 100%;
}

.icon {
  border: none;
  font-size: 15px;
  padding: 7px;
  border-radius: 50%;
  color: #0295D4;
  background-color: #DEECF3;
}

h6 {
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px
}

.ant-card-height{
  height: 100%;
}
.no-shadow {
  box-shadow: none !important;
}

.remarks-card {
  background-color: #F7F9FC;
  overflow-y: auto;
  
}

.label{
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    line-height: 1.6666666666666667;
    list-style: none;
    font-weight: normal;
}

.specification-standard {
  background-color: #D9F1FD;
  border-radius: 18px;
  align-items: center;
  padding: 2px 15px;
  text-align: center;
}

.ant-divider-horizontal{
  margin: 8px 0;
}
.tooltip-heading{
  margin-bottom: 8px;
  font-size: 12px;
  color:'#4A5E6D' ;
  font-weight: 600;
}

.ant-input-number-outlined {
  width: 100%;
}
.ant-picker-outlined {
  width: 100% !important;
}
@import '~antd/dist/reset.css';
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700|Poppins:400,500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.toggle-activated {
  background-color: #14db14;
  vertical-align: top;
  color: #fff;
}

.toggle-deactivated {
  background-color: #ff0000;
  vertical-align: top;
  color: #fff;
  background-image: none;
}

.disabled-Edit-Icon {
  color: #8080807d;
}

.disabled-icon {
  color: rgba(0, 0, 0, 0.25) !important;
  /* Set the desired disabled color */
  pointer-events: none;
  /* Disable pointer events on the icon */
}

.Editable-Icon {
  color: #1890ff;
}

.ant-descriptions-item-label > span {
  font-weight: bold;
  font-size: small;
}

.contracts .ant-table-thead > tr > th {
  background: #2195f3 !important;
  color: #fff;
  padding: 8px 8px;
}

.contracts-PO-List .ant-table-cell {
    padding: 12px !important;
}

/* .contracts-list .ant-table-cell {
    padding: 12px !important;
} */

/* .contracts-PO-List .ant-table {
  border-spacing: 16px;
}*/

.contracts-PO-List .ant-table-thead > tr > th {
  background: #f2f7fd !important;
  color: #130202;
  /* padding: 8px 12px !important; */
}

.contracts-list .ant-table-thead > tr > th {
  background: #f2f7fd !important;
  color: #130202;
  /* padding: 8px 12px !important; */
}

.purchase-Details .ant-table-thead > tr > th {
  background: #f2f7fd !important;
  color: #130202;
}

.purchase-Details .ant-table-footer {
  padding: 0px !important;
  margin-top: 8px !important;
}

.approve {
  background-Color: #B2F9CC !important;
   border-Radius: 4px;
   color: #0B903C !important;
   padding: 12px 8px ;
   border: none;
}

.reject {
  background-Color: #FFD2D2 !important;
  border-Radius: 4px;
  color: #DE2C2C !important;
  padding: 12px 8px ;
  border: none;
}

.descriptions {
  font-weight: 500;
  color: #323F49;
}

.contracts-List .ant-table-tbody>tr>td  {
    border: none !important;
} 
/* 
.contracts-PO-List .ant-table-row {
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px #0000001a;
} */

.inner-table .ant-table-thead > tr > th {
  background: #fafafa !important;
  color: rgba(0, 0, 0, 0.88) !important;
  padding: 8px 8px;
}

/* .ant-table-thead > tr > th,
.ant-table tfoot > tr > th {
  padding: 8px 8px;
} */

/* .ant-table-thead>tr>th {
    background-color: #e6e0e0!important;
} */
/* 
.ant-table-thead>tr>th {
    background-color: #e6e0e0!important;
}
.ant-table.ant-table-bordered td, .ant-table.ant-table-bordered th {
    border: 0.5px solid #dee2e6;
} */

/* .ant-table-tbody > tr > td,
.ant-table tfoot > tr > td {
  padding: 0.3rem !important;
} */

/* .ant-card-head {
    min-height: 40px !important;
    line-height: 0;
} */

/* th {
    white-space: nowrap;
} */

.contracts
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th {
  text-align: center;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: bold;
}

.contracts .ant-table-thead > tr > th,
.contracts .ant-table-tbody > tr > td,
.contracts .ant-table tfoot > tr > th,
.contracts .ant-table tfoot > tr > td {
  padding: 0.3rem !important;
}

.contracts .ant-table-thead .ant-table-row-expand-icon-cell {
  background: #2195f3 !important;
  color: #fff;
}

.contracts .ant-table-thead .ant-table-selection-column {
  background: #2195f3 !important;
  color: #fff;
}

.ant-list-item {
  padding: 0.3rem !important;
}

.export-excel-btn {
  color: #fff;
  margin-right: 20px;
  background-color: #088f08;
  border-color: #088f08;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  -webkit-box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  border-radius: 4px;
}

.export-excel-btn:hover,
.export-excel-btn:focus,
.export-excel-btn:active,
.export-excel-btn.active {
  color: #fff;
  background-color: rgba(0, 128, 0, 0.9);
  border-color: rgba(0, 128, 0, 0.9);
}

.export-excel-btn-disabled {
  color: #fff;
  margin-right: 20px;
}

span.ant-descriptions-item-label {
  font-weight: bold;
  /* font-size: small; */
}

/* 
.ant-form-item {
    margin-bottom: 40px;
} */

/* material floating label and animated underline bar -- https://auralinna.blog/post/2018/how-to-create-material-design-like-form-text-fields*/

/* .form-field {
    display: block;
    margin-bottom: 16px;
}

.form-field__label {
    font-size: 0.75rem;
    transform: translateY(-14px);
    display: block;
    font-weight: normal;
    left: 0;
    margin: 0;
    padding: 18px 12px 0;
    position: absolute;
    top: 0;
    transition: all 0.4s;
    width: 100%;
}

.form-field__control {
    background: #eee;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.form-field__bar {
    border-bottom: 2px solid #b11adc;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    transform: scaleX(0);
    transition: all 0.4s;
    width: 1%;
}

.form-field__input,
.form-field__textarea {
    appearance: none;
    background: transparent;
    border: 0;
    border-bottom: 1px solid #999;
    color: #333;
    display: block;
    font-size: 1.2rem;
    margin-top: 24px;
    outline: 0;
    padding: 0 12px 10px 12px;
    width: 100%;
}

.form-field__input:-ms-input-placeholder~.form-field__label,
.form-field__textarea:-ms-input-placeholder~.form-field__label {
    font-size: 1.2rem;
    transform: translateY(0);
}

.form-field__input:placeholder-shown~.form-field__label,
.form-field__textarea:placeholder-shown~.form-field__label {
    font-size: 1.2rem;
    transform: translateY(0);
}

.form-field__input:focus~.form-field__label,
.form-field__textarea:focus~.form-field__label {
    color: #b11adc;
    font-size: 0.75rem;
    transform: translateY(-14px);
}

.form-field__input:focus~.form-field__bar,
.form-field__textarea:focus~.form-field__bar {
    border-bottom: 2px solid #b11adc;
    transform: scaleX(150);
}

.form-field__textarea {
    height: 150px;
} */
/* https://codesandbox.io/s/antd-float-label-forked-k8p00?file=/FloatInput/index.js ---https://github.com/ant-design/ant-design/issues/16323 */

.ant-table {
  scrollbar-color: unset !important;
}

.ant-table-wrapper .ant-table-row-expand-icon {
  background-color: #64748B !important;
  color: whitesmoke !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0083C9 !important; 
  border-color: #0083C9 !important;
}

.selected-row, .expanded-row {
  background-color: #F8FBFF !important;
}

.ant-table-wrapper .ant-table-tbody .ant-table-row.ant-table-row-selected >.ant-table-cell {
  background: #F8FBFF !important;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.default-card-class .ant-card-head {
  background-color: #2193f3 !important;
  color: #fff !important;
}

.highlighted-row {
  background-color: yellow;
  /* or any other styling you want */
}

.ant-card-small > .ant-card-body {
  padding: 6px !important; 
}

.row-even {
  background-color: #fff; 
}

.row-odd {
  background-color: #F8FBFF;
}

.filter-by-status {
  width: 175px;
  height: 28px;
  border-radius: 4px;
  border: 1px;
}

.so-card-title {
  color: #323F49;
  font-size: 24px;
  font-weight: 600;
}

.card-title {
  color: #323F49;
  font-size: 24px;
  font-weight: 600;
}

.so-card-container {
  background-color: #F5F5F5;
  border: none;
  overflow: hidden;
}

.card-container {
  background-color: #F5F5F5;
  border: none;
  overflow: hidden;
}

.so-card-shadow {
  border: none;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.05);
}

.card-shadow {
  border: none;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.05);
}

.action-icons {
  border: none;
  background-color: #DEECF3;
  color: #0295D4;
  padding: 6px;
  font-size: 14px;
  border-radius: 50%;
}

.mr-8 {
  margin-right: 8px;
}

.link {
  color: #0083C9;
  font-weight: 500;
}

.so-row-spaced {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.new-button {
  padding: 12px;
  background-color: #0295D4 !important;
  color: #F5F5F5 !important;
}

.new-button:disabled {
  background-color: #F9F9FA !important;
  color: #94A3B8 !important;
  border-color: #94A3B8 !important;
  cursor: not-allowed;
}

.so-input-search-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.input-search-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.card-radius {
  border-radius: 8px;
}

.card-title-buttons {
  gap: 8px;
  margin-top: 16px;
}

.page-total-items {
  font-weight: 400;
  font-size: 14px;
  color: #64748B;
}

.table-name-column {
  color: #323F49;
  font-weight: 600;
}

.table-other-columns {
color: #40474F;
font-weight: 400;
}

.popover-title {
  color: #323F49;
  font-size: 14px;
  font-weight: 600;
}
.download-btn {
  border: none;
  font-size: 16px;
  padding: 8px;
  border-radius: 50%;
  color: #0295D4 !important;
  background-color: #DEECF3 !important;
}
.active-tag {
  font-size: 10px;
  border-radius: 10px;
  border-color: #0EAD49;
  background-color: #E9FCF0;
  color: #0EAD49;
}
.arrow-left {
  font-size: 18px;
  color: #6B7280;
}

.arrow-left:hover {
  color: #6B7280;
}

.business-no {
  font-size: 16px;
  font-weight: 600;
  color: #323F49;
}

.card-br-8 {
  border-radius: 8px;
}
 
.mb-12 {
  margin-bottom: 12px;
}

:root {
  --active-tab-color: #0083C9; 
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--active-tab-color) !important;
  font-weight: 500;
}

.ant-tabs-ink-bar {
  background-color: var(--active-tab-color) !important;
}

.card-title-md {
  color: #323F49;
  font-size: 16px;
  font-weight: 600;
}

.message-icon {
  background-color: #0083C91A;
  color: #0083C9;
  padding: 6px;
  font-size: 16px;
  border-radius: 50%;
}

.table-title {
  font-size: 14px;
  font-weight: 500;
  color: #94A3B8CC;
}

.xmark-icon {
  border: none;
  font-size: 20px;
  color: #6B7280;
  box-shadow: none;
}

.drawer-title {
  font-size: 20px;
  font-weight: 500;
  color: #323F49;
}

.drawer-footer-div {
  display: flex;
  justify-content: flex-end;
  color: #0083C9;
  font-weight: 400;
  margin: 8px;
}

.contracts-list .ant-radio-checked .ant-radio-inner {
  background-color: #0083C9; 
}

.contracts-list .ant-radio:hover .ant-radio-inner {
  border-color: #0083C9; 
}

.delete-filled {
  color: #EF4444;
  font-size: 16px;
  background-color: #FEE2E2;
  border-radius: 100px;
  padding: 4px;
}

.surveyor-card {
  border-radius: 4px;
  background-color: #f7f9fc;
  width: 90%;
}

.surveyor-type {
  text-align: center;
  background-color: #d9f1fd;
  border-radius: 18px;
  font-weight: 400;
  color: #323f49;
  padding: 0 8px;
}

.plusCircled-icon {
  border: none;
  padding: 14px;
  border-radius: 50%;
  color: #0295D4;
  background-color: #DEECF3;
}

.file-upload-form {
  height: 300px; /* Adjust this value to your desired height */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures content is spaced evenly */
  border: 1px solid #ddd; /* Optional for visual debugging */
  padding: 16px; /* Optional for spacing */
}

.currency-style, .quality-style {
  color: #64748B;
  font-size: 10px;
  padding-left: 5px;
}

.send-approval-button {
  background-color: #E5F3FA !important;
  border-Radius: 4px;
  color: #0295D4 !important;
  padding: 12px 8px;
  border: none;
}

.comments-header {
  font-size: 16px;
  margin-bottom: 24px;
}

.comments-title {
  font-weight: 600;
  font-size: 20px;
}

.comments-container {
  display: flex;
  flex-direction: column;
  height: 90%;
  position: relative;
}

.comments-list {
  flex: 1;
  overflow-y: auto;
  min-height: 0;
}

.comments-search {
  margin-bottom: 24px;
}

.comment-row {
  margin-bottom: 8px;
}

.comment-avatar {
  background-color: #DBF1FD;
  border-radius: 50%;
  width: 28px; 
  height: 28px; 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.comment-username {
  font-size: 16px;
  font-weight: 500;
}

.comment-time {
  color: #94A3B8;
}

.comment-feature {
  color: #0083C9;
  font-size: 12px;
  font-weight: 500;
  margin-left: 34px;
  margin-bottom: 4px;
}

.comment-text {
  font-size: 14px;
  margin-left: 34px;
  color: #4A5E6D;
}

.comment-input-container {
  position: relative;
}

.comment-input {
  font-size: 14px;
}

.comment-button {
  position: absolute;
  right: 12px;
  bottom: 12px;
  z-index: 1;
}

.empty-tag {
  font-size: 14px;
}

.layout {
  background-color: #F5F5F5;
}

.flex {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.full-width {
  width: 100%;
}

.overflowX {
  overflow-x: auto;
}

.NA {
  color: #94A3B8;
}

.file {
  background-color: #F7F9FC;
  padding: 4px;
  border-radius: 4px;
}

.vendors {
  background-color: #F2F7FD;
  width: fit-content;
  padding: 0px 4px;
  margin: 2px;
}

.agreement-bg {
  background-color: #F2F7FD;
}

.agreement-btn {
  display: flex;
  justify-content: end;
  margin-top: 10px;
}

.divider-margin {
  margin: 2px;
}

.nowrap {
  white-space: nowrap;
}

.vessel-owner {
  color: #1D4ED8;
  font-weight: 500;
}

.supplier {
  color: #0E7490;
  font-weight: 500;
}

.buyer {
  color: #7E22CE;
  font-weight: 500;
}

.font-14 {
  font-size: 14px;
}

.font-10 {
  font-size: 10px;
}

.mv-dead-freight {
  color: #FF6F61;
}

.mv-cards {
  background-color: #F8FAFC;
}

.edit-delete {
  display: flex;
  justify-content: center;
  align-items: center;
}

.terms-divider {
  margin-top: 0px;
  margin-bottom: 10px;
}

.surveyor-section {
  position: relative;
  width: 90%;
}

.surveyor-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  margin-bottom: 8px;
}

.ant-radio-button-wrapper-checked {
  background-color: #0083C9 !important; 
  color: white !important; 
  border-color: #0083C9 !important; 
}

.ant-radio-button-wrapper {
  padding: 0 12px !important;
  transition: all 0.3s ease !important; 
}

.addendums-height {
  max-height: 275px;
  overflow-x: auto;
}

.addendum-card {
  border-radius: 4px;
  background-color: #F8F8F8;
}

.currency {
  background-color: #D9F1FD;
  padding: 2px 6px;
  border-radius: 20px;
}

.divider {
  margin-top: 0;
  margin-bottom: 10px;
}

.terms-card {
  background-color: #F7F9FC;
  height: 300px;
  overflow-y: auto;
}

.table-row {
  border-bottom: 0.5px solid rgba(209, 214, 219, 0.52);
}

.table-row td {
  padding: 8px 10px;
}
.price-card-text {
  font-size: 12px;
  font-weight: 500;
  color: #4A5E6D;
  line-height: 26px;
}

.price-card-heading {
  font-size: 14px;
  font-weight: 600;
  color: #323F49;
  line-height: 26px;

}

.price-card-green {
  font-size: 14px;
  font-weight: 600;
  color: #0EAD49;
  line-height: 26px;

}

.price-card-red {
  font-size: 14px;
  font-weight: 600;
  color: #EF4444;
  line-height: 26px;
}

.small-text {
  font-size: 12px;
  color: #64748B;
}


.ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table{
  margin-block: 0 !important;
  margin-inline: 0 !important;
}

.ant-switch-checked {
  background-color: #0295D4 !important;
}

.total-row {
  background-color: rgb(244, 244, 244) !important;
  font-weight: bold !important;
}

.file-container {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px;
  border-radius: 8px;
  background-color: #f5f5f5;
  width: fit-content;
  max-width: 500px;
}

.file-display {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  object-fit: cover;
}

.file-icon {
  font-size: 24px;
  color: #0083C9;
}

.file-details {
  display: flex;
  flex-direction: column;
}

.file-size {
  font-size: 10px;
  color: #888;
}
.ant-timeline .ant-timeline-item {
  padding-bottom: 0 !important;
}

.ant-timeline .ant-timeline-item-tail{
  height: 100% !important;
  }

.main-row td {
  border-top: 0.5px solid rgba(221, 221, 221, 0.855) !important;
}

.sub-row td {
  border-top: 0.5px snow #F2F7FC !important; 
}

.procurement-list .ant-table-thead > tr > th {
  background: #f2f7fd;
  color: #130202;
}

.procurement-list .ant-radio-checked .ant-radio-inner {
  background-color: #0083C9; 
}

.procurement-list .ant-radio:hover .ant-radio-inner {
  border-color: #0083C9; 
}

.procurement-list .ant-table td {
  border-top: 0.5px solid rgb(221, 221, 221);
  padding: 0 !important;
  margin: 0 !important;
}
